import cn from "classnames";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import React, { FC } from "react";
import Markdown from "react-markdown";

import { User } from "~/api";
import SidebarLayout from "~/components/ui/SidebarLayout";
import { useUI } from "~/providers/ManagedUIContext";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const NotificationsSidebarView: FC<React.PropsWithChildren<unknown>> = () => {
  const { closeSidebar } = useUI();
  const handleClose = () => closeSidebar();
  const { data: notifications } = User.useNotifications();

  return (
    <SidebarLayout className="min-h-[calc(100vh-322px)]" handleClose={handleClose}>
      <div className="flex-1 px-4 sm:px-6">
        <Link href="/cart">
          {/* <Text variant="sectionHeading">Notifications</Text> */}
          Notifications
        </Link>
        {!notifications?.length && (
          <div className="mt-2 bg-gray-200 p-8 text-center">No Notifications</div>
        )}
        <ul>
          {notifications?.map((item) => {
            return (
              <li
                className={cn("py-2 px-4 mb-2 rounded", {
                  "bg-red-200": item.type == "ERROR",
                  "bg-yellow-200": item.type == "WARN",
                  "bg-gray-200": item.type == "INFO",
                  "bg-green-200": item.type == "SUCCESS",
                })}
                key={item.timestamp}
              >
                <div className="flex items-baseline justify-between">
                  <div className="font-bold">{item.title}</div>
                  <div
                    className="min-w-max text-xs font-light italic"
                    title={dayjs(item.timestamp).fromNow()}
                  >
                    {dayjs(item.timestamp).format("L LT")}
                  </div>
                </div>
                <div className="text-sm">
                  <Markdown>{item.message}</Markdown>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </SidebarLayout>
  );
};

export default NotificationsSidebarView;
