import useSWR from "swr";

import { fetchWrapper, toUrl } from "~/utils/fetchWrapper";

const useOperation = (id: Paths.OperationsRetrieve.Parameters.Id) => {
  return useSWR<Paths.OperationsRetrieve.Responses.$200>(`api/v1/operations/${id}`);
};

const useOperations = (queryParams: Paths.OperationsList.QueryParameters) => {
  return useSWR<Paths.OperationsList.Responses.$200>(toUrl("/api/v1/operations", queryParams));
};

const getOperations = (queryParams: Paths.OperationsList.QueryParameters) => {
  return fetchWrapper.get<Paths.OperationsList.Responses.$200>(
    toUrl("/api/v1/operations", queryParams),
  );
};

const getOperation = (id: Paths.OperationsRetrieve.Parameters.Id) => {
  return fetchWrapper.get<Paths.OperationsRetrieve.Responses.$200>(`/api/v1/operations/${id}`);
};

const dowloadOperations = async (
  queryParams: Paths.OperationsList.QueryParameters,
  fileName: string,
) => {
  const data = await fetchWrapper.getText(toUrl("/api/v1/operations", queryParams), {
    headers: {
      Accept: "text/csv",
    },
  });

  const blob = new Blob([data], { type: "octet/stream" }),
    url = window.URL.createObjectURL(blob),
    a = document.createElement("a");

  a.href = url;
  a.download = fileName;
  a.setAttribute("style", "display: none");
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export async function uploadOperationsOfx(files: FileList) {
  const url = "/api/v1/operations/upload";
  return fetchWrapper.upload<Paths.OperationsUploadCreate.Responses.$201>(url, files);
}

const useOperationHints = (id: Paths.OperationsCategoryHintList.Parameters.Id) => {
  const url = `/api/v1/operations/${id}/category_hint`;

  return useSWR<Paths.OperationsCategoryHintList.Responses.$200>(url);
};

const saveAsTemplate = (id: Paths.OperationsMakeTemplateCreate.Parameters.Id) => {
  const url = `/api/v1/operations/${id}/make_template`;
  return fetchWrapper.post<
    Paths.OperationsMakeTemplateCreate.RequestBody,
    Paths.OperationsMakeTemplateCreate.Responses.$200
  >(url, {} as Paths.OperationsMakeTemplateCreate.RequestBody);
};

const makeRecurring = (id: Paths.OperationsMakeRecurringCreate.Parameters.Id) => {
  const url = `/api/v1/operations/${id}/make_recurring`;
  return fetchWrapper.post<
    Paths.OperationsMakeRecurringCreate.RequestBody,
    Paths.OperationsMakeRecurringCreate.Responses.$200
  >(url, {} as Paths.OperationsMakeRecurringCreate.RequestBody);
};

const createOperation = async (o: Paths.OperationsCreate.RequestBody) => {
  const url = `/api/v1/operations`;
  return fetchWrapper.post<
    Paths.OperationsCreate.RequestBody,
    Paths.OperationsCreate.Responses.$201
  >(url, o);
};

const patchOperation = async (
  id: Paths.OperationsPartialUpdate.Parameters.Id,
  o: Paths.OperationsPartialUpdate.RequestBody,
) => {
  const url = `/api/v1/operations/${id}`;
  return fetchWrapper.patch<
    Paths.OperationsPartialUpdate.RequestBody,
    Paths.OperationsPartialUpdate.Responses.$200
  >(url, o);
};

const convertToTransfer = async (
  id: Paths.OperationsConvertToTransferCreate.Parameters.Id,
  transfer: Paths.OperationsConvertToTransferCreate.RequestBody,
) => {
  const url = `/api/v1/operations/${id}/convert_to_transfer`;
  return fetchWrapper.post<
    Paths.OperationsConvertToTransferCreate.RequestBody,
    Paths.OperationsConvertToTransferCreate.Responses.$200
  >(url, transfer);
};

const deleteOperation = (id: Paths.OperationsDestroy.Parameters.Id) => {
  return fetchWrapper.delete<Paths.OperationsDestroy.Responses.$204>(`/api/v1/operations/${id}`);
};

const mark_as = async (
  selected_operations: Components.Schemas.Operation[],
  action: Components.Schemas.ActionEnum,
  value?: string,
) => {
  const ids = Object.values(selected_operations).map((o) => o.id);
  const url = "/api/v1/operations/mark_as";

  return fetchWrapper.post<
    Paths.OperationsMarkAsCreate.RequestBody,
    Paths.OperationsMarkAsCreate.Responses.$201
  >(url, { operation_ids: ids, action: action, value });
};

const useAccounts = () => {
  return useSWR<Paths.AccountsList.Responses.$200>(`/api/v1/accounts`);
};

const useAccount = (id: Paths.AccountsRetrieve.Parameters.Id) => {
  return useSWR<Paths.AccountsRetrieve.Responses.$200>(`/api/v1/accounts/${id}`);
};

const useCategories = () => {
  return useSWR<Paths.CategoriesList.Responses.$200>(
    `/api/v1/categories?ordering=-is_active,full_name`,
  );
};

const useUnassignedCategory = () => {
  const { data: categories } = useCategories();
  if (!categories) {
    return null;
  }
  return categories.find((c) => c.uid === "NotAssigned");
};

const useTags = (search?: Paths.TagsList.Parameters.Search) => {
  const url = toUrl("/api/v1/tags", { search });

  return useSWR<Paths.TagsList.Responses.$200>(url);
};

const useImportInfo = (
  shouldFetch: boolean,
  id: Paths.IntegrationsPlaidImportInfoRetrieve.Parameters.ExternalId,
) => {
  const url = shouldFetch ? `/api/v1/integrations/plaid/import_info/${id}` : null;

  return useSWR<Paths.IntegrationsPlaidImportInfoRetrieve.Responses.$200>(url);
};

const transfer = (transfer: Paths.OperationsTransferCreate.RequestBody) => {
  const url = `/api/v1/operations/transfer`;

  return fetchWrapper.post<
    Paths.OperationsTransferCreate.RequestBody,
    Paths.OperationsTransferCreate.Responses.$200
  >(url, transfer);
};

const updateTransfer = (
  id: Paths.OperationsTransferUpdate.Parameters.TransferId,
  transfer: Paths.OperationsTransferUpdate.RequestBody,
) => {
  const url = `/api/v1/operations/transfer/${id}`;
  return fetchWrapper.put<
    Paths.OperationsTransferUpdate.RequestBody,
    Paths.OperationsTransferUpdate.Responses.$200
  >(url, transfer);
};

const deleteTransfer = (id: Paths.OperationsTransferDestroy.Parameters.TransferId) => {
  const url = `/api/v1/operations/transfer/${id}`;
  return fetchWrapper.delete<Paths.OperationsTransferUpdate.Responses.$200>(url);
};

const getOperationsByTransferId = async (transfer_id: string) => {
  const url = toUrl("/api/v1/operations", {
    search: transfer_id,
  });
  return fetchWrapper.get<Paths.OperationsList.Responses.$200>(url).then((data) => data.results);
};

const splitOperation = async (
  operation_id: Paths.OperationsSplitCreate.Parameters.Id,
  items: Paths.OperationsSplitCreate.RequestBody,
) => {
  const url = `/api/v1/operations/${operation_id}/split`;

  return fetchWrapper.post<
    Paths.OperationsSplitCreate.RequestBody,
    Paths.OperationsSplitCreate.Responses.$201
  >(url, items);
};

const useHint = (type: "memo" | "payee", search: string) => {
  return useSWR<
    Paths.OperationsHintMemoList.Responses.$200 | Paths.OperationsHintPayeeList.Responses.$200
  >(toUrl(search ? `/api/v1/operations/hint/${type}` : "", { search }));
};

const useBudgets = () => {
  return useSWR<Paths.BudgetsList.Responses.$200>("/api/v1/budgets");
};

const createBudget = (budget: Paths.BudgetsCreate.RequestBody) => {
  const url = `/api/v1/budgets`;
  return fetchWrapper.post<Paths.BudgetsCreate.RequestBody, Paths.BudgetsCreate.Responses.$201>(
    url,
    budget,
  );
};

const updateBudget = (
  id: Paths.BudgetsPartialUpdate.Parameters.Id,
  budget: Paths.BudgetsPartialUpdate.RequestBody,
) => {
  const url = `/api/v1/budgets/${id}`;
  return fetchWrapper.patch<
    Paths.BudgetsPartialUpdate.RequestBody,
    Paths.BudgetsPartialUpdate.Responses.$200
  >(url, budget);
};

const deleteBudget = (id: Paths.BudgetsDestroy.Parameters.Id) => {
  const url = `/api/v1/budgets/${id}`;
  return fetchWrapper.delete<Paths.BudgetsDestroy.Responses.$204>(url);
};

const useSavedSearches = () => {
  return useSWR<Paths.SavedsearchesList.Responses.$200>("/api/v1/savedsearches");
};

const createSavedSearch = (savedSearch: Paths.SavedsearchesCreate.RequestBody) => {
  const url = `/api/v1/savedsearches`;
  return fetchWrapper.post<
    Paths.SavedsearchesCreate.RequestBody,
    Paths.SavedsearchesCreate.Responses.$201
  >(url, savedSearch);
};

const updateSavedSearch = (
  id: Paths.SavedsearchesDestroy.Parameters.Id,
  savedSearch: Paths.SavedsearchesPartialUpdate.RequestBody,
) => {
  const url = `/api/v1/savedsearches/${id}`;
  return fetchWrapper.patch<
    Paths.SavedsearchesPartialUpdate.RequestBody,
    Paths.SavedsearchesPartialUpdate.Responses.$200
  >(url, savedSearch);
};

const deleteSavedSearch = (id: Paths.SavedsearchesDestroy.Parameters.Id) => {
  const url = `/api/v1/savedsearches/${id}`;
  return fetchWrapper.delete<Paths.SavedsearchesDestroy.Responses.$204>(url);
};

const api = {
  useOperation,
  useOperations,
  getOperations,
  getOperation,
  useOperationHints,
  useAccounts,
  useAccount,
  useCategories,
  useUnassignedCategory,
  useTags,
  useImportInfo,

  createOperation,
  patchOperation,
  deleteOperation,
  getOperationsByTransferId,

  saveAsTemplate,
  makeRecurring,
  mark_as,
  convertToTransfer,
  splitOperation,
  useHint,

  dowloadOperations,

  transfer,
  updateTransfer,
  deleteTransfer,

  useBudgets,
  createBudget,
  updateBudget,
  deleteBudget,

  uploadOperationsOfx,

  useSavedSearches,
  createSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
};

export default api;
