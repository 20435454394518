import { Combobox, Transition } from "@headlessui/react";
import { LockClosedIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import cn from "classnames";
import { FC, Fragment, useState, FocusEvent, useEffect } from "react";

import { Operations } from "~/api";
import { Pill } from "~/components/ui";

export type CategorySelectProps = {
  category?: Components.Schemas.Category; // Make category optional
  onChange: (newCategory: Components.Schemas.Category) => void;
  disabled?: boolean;
  className?: string;
  include_inactive?: boolean;
};

export const CategorySelect: FC<CategorySelectProps> = ({
  category,
  onChange,
  className,
  include_inactive = false,
  ...rest
}) => {
  const { data: categories } = Operations.useCategories();

  const [selectedCategory, setSelectedCategory] = useState<Components.Schemas.Category | null>(null);
  const [query, setQuery] = useState("");
  
  // Update internal state when category prop changes
  useEffect(() => {
    if (categories) {
      const tmp = category?.id ? categories.find((a) => a.id === category.id) : null;
      setSelectedCategory(tmp || null);
      setQuery(tmp?.name || "");
    }
  }, [category, categories]);

  return (
    <Combobox
      value={selectedCategory}
      onChange={(cat) => {
        if (!cat) {
          setSelectedCategory(null);
          return;
        }

        setSelectedCategory(cat);
        onChange(cat);
      }}
      {...rest}
    >
      <div className={cn("relative w-full", className)}>
        <div className="relative w-full cursor-default bg-white text-left">
          <Combobox.Input
            className={cn(
              "bg-primary px-2 py-1 border w-full pr-8",
              "focus:outline-none focus:shadow-outline-normal",
            )}
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(category: Components.Schemas.Category | null) => category?.name || ""}
            autoComplete="off"
            autoCorrect="off"
            placeholder="Category"
            onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            onClick={() => setQuery("")}
          >
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow focus:outline-none sm:text-sm">
            {categories
              ?.filter(
                (c) =>
                  c.full_name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
                  c.description?.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
              )
              .filter((t) => (include_inactive ? true : t.is_active))
              .map((c) => (
                <Combobox.Option
                  key={c.id}
                  value={c}
                  as={Fragment}
                  disabled={!c.is_active || !c.is_assignable}
                >
                  {({ active, selected }) => (
                    <li
                      className={cn("truncate whitespace-nowrap p-2 cursor-pointer", {
                        "bg-blue-500 text-white": active,
                        "bg-white text-black": !active,
                        "bg-lime-200 text-gray-400": selected,
                        "bg-red-300 ": !c.is_active,
                        italic: !c.is_assignable,
                      })}
                    >
                      <div>
                        {!c.is_assignable && <LockClosedIcon className="inline h-3" />}
                        <Pill bgColor={c.background_color} txColor={c.color}>
                          {c.full_name}
                        </Pill>
                      </div>
                      <div className="flex justify-between text-xs italic">
                        <span>{c.description}</span>
                      </div>
                    </li>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};
