import cn from "classnames";
import Link from "next/link";
import React from "react";

import { MyLink } from "../TopNav/MyLink";

const Logo = ({ className = "", ...props }) => {
  const svgclassName = cn("fill-current inline mr-2", className);

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 485 485" className={svgclassName}>
        <path d="M214.447 212.325h-30c0 26.824 18.291 49.447 43.053 56.078v12.579h30v-12.579c24.762-6.63 43.053-29.253 43.053-56.078 0-27.442-14.402-47.104-40.552-55.364l-2.501-.79V98.313c7.838 4.98 13.053 13.734 13.053 23.688h30c0-26.824-18.291-49.448-43.053-56.078V53.344h-30v12.579c-24.762 6.63-43.053 29.253-43.053 56.078 0 27.442 14.402 47.104 40.552 55.364l2.501.79v57.858c-7.838-4.98-13.053-13.734-13.053-23.688zm56.106 0c0 9.954-5.216 18.708-13.053 23.688V188.23c9.068 4.708 13.053 12.223 13.053 24.095zM214.447 122c0-9.954 5.216-18.708 13.053-23.688v47.783c-9.068-4.707-13.053-12.222-13.053-24.095zM242.5 0C150.326 0 75.337 74.989 75.337 167.163v150.675C75.337 410.011 150.326 485 242.5 485s167.163-74.989 167.163-167.163V167.163C409.663 74.989 334.674 0 242.5 0zm0 30c75.632 0 137.163 61.531 137.163 137.163S318.132 304.326 242.5 304.326s-137.163-61.531-137.163-137.163S166.868 30 242.5 30zm135.264 235.261c-10.875 64.836-67.385 114.402-135.264 114.402s-124.389-49.565-135.264-114.402c30.414 41.821 79.713 69.065 135.264 69.065s104.85-27.244 135.264-69.065zM242.5 455c-67.879 0-124.389-49.565-135.264-114.402 30.414 41.821 79.713 69.065 135.264 69.065s104.85-27.244 135.264-69.065C366.889 405.435 310.379 455 242.5 455z" />
      </svg>

      <span className="cursor-pointer">
        <MyLink href="/">{props.children}</MyLink>
      </span>
    </>
  );
};

export default Logo;
