import { Transition } from "@headlessui/react";
import cn from "classnames";
import dynamic from "next/dynamic";
import React, { FC, Fragment, useState } from "react";
import Markdown from "react-markdown";

import { NotificationsSidebarView } from "~/components/common/sidebar";
import { Cross } from "~/components/icons";
import { Container, Footer, Modal, Sidebar, TopNav } from "~/components/ui";
import { Button } from "~/components/ui/forms";
import { MODAL_VIEWS, SIDEBAR_VIEWS, useUI } from "~/providers/ManagedUIContext";
import { useNotification, Notification } from "~/providers/Notification";
import { useAcceptCookies } from "~/utils/hooks/useAcceptCookies";
// const Loading: FC = () => (
//   <div className="flex items-center justify-center p-3 text-center w-80 h-80">
//     <LoadingDots />
//   </div>
// );

// const dynamicProps = {
//   // eslint-disable-next-line react/display-name
//   loading: () => <Loading />,
// };

const FeatureBar = dynamic(
  () =>
    import(
      "~/components/ui/FeatureBar"
    ) /*, dynamicProps // dynamicProps is causing mismatch between ssr and client */,
);

type ModalViewProps = {
  modalView: MODAL_VIEWS;
  closeModal(): any;
};

const CustomViewModal = dynamic(
  () =>
    import("~/components/dashboard/charts/MonthlyTopExpenseCategoriesChart" /*, dynamicProps*/),
);

const ModalView: FC<React.PropsWithChildren<ModalViewProps>> = ({ modalView, closeModal }) => {
  return (
    <Modal onClose={closeModal}>
      {/* {modalView === "dashboard/Chart/MonthlyTopExpenseCategoriesChart" && <CustomViewModal />} */}
    </Modal>
  );
};

const ModalUI: FC<React.PropsWithChildren<unknown>> = () => {
  const { context, closeModal } = useUI();
  return context.displayModal ? (
    <ModalView modalView={context.modalView} closeModal={closeModal} />
  ) : null;
};

const SidebarView: FC<
  React.PropsWithChildren<{ sidebarView: SIDEBAR_VIEWS; closeSidebar(): any }>
> = ({ sidebarView, closeSidebar }) => {
  return (
    <Sidebar onClose={closeSidebar}>
      {sidebarView === "NOTIFICATION_VIEW" && <NotificationsSidebarView />}
    </Sidebar>
  );
};

const SidebarUI: FC<React.PropsWithChildren<unknown>> = () => {
  const { context, closeSidebar } = useUI();
  return context.displaySidebar ? (
    <SidebarView sidebarView={context.sidebarView} closeSidebar={closeSidebar} />
  ) : null;
};

type LayoutProps = {
  is_full_width?: boolean;
};

function NotificationItem({
  notification,
  dismiss,
}: {
  notification: Notification;
  dismiss: (id: number) => void;
}) {
  const [show, setShow] = useState(true);

  let cancelTimeout: NodeJS.Timeout | undefined = undefined;

  if (notification.dismiss_after) {
    cancelTimeout = setTimeout(() => setShow(false), notification.dismiss_after);
  }

  return (
    <Transition
      appear={true}
      show={show}
      as={Fragment}
      enter="transition transform duration-1500"
      enterFrom="opacity-0 scale-0"
      enterTo="opacity-100 scale-105"
      leave="transition transform duration-500"
      leaveFrom="opacity-100 scale-105"
      leaveTo="opacity-0 scale-0"
      afterLeave={() => dismiss(notification.id)}
    >
      <li
        className={cn("flex items-center gap-4 border border-l-4 p-3 shadow-2xl", {
          "bg-red-200 border-l-red-500": notification.type == "ERROR",
          "bg-yellow-200 border-l-yellow-500": notification.type == "WARN",
          "bg-blue-200 border-l-blue-500": notification.type == "INFO",
          "bg-green-200 border-l-green-500": notification.type == "SUCCESS",
        })}
      >
        <div className="w-fit">
          <Markdown>{notification.message}</Markdown>
        </div>
        <Cross
          className="box-content h-4 cursor-pointer p-2 hover:text-gray-600"
          onClick={() => {
            if (cancelTimeout) clearTimeout(cancelTimeout);
            setShow(false);
          }}
        />
      </li>
    </Transition>
  );
}

function NotificationsUI() {
  const { notifications, dismiss } = useNotification();

  const to_display = notifications.slice(0, 5);

  if (to_display.length == 0) return null;
  return (
    <ul className="fixed bottom-8 left-8 z-50 flex w-fit flex-col items-start gap-4">
      {to_display.map((n) => (
        <NotificationItem key={n.id} notification={n} dismiss={dismiss} />
      ))}
    </ul>
  );
}

const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({ is_full_width, children }) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies();

  return (
    <>
      <TopNav />
      <Container id="content" clean={is_full_width}>
        <main className="min-h-fit">{children}</main>
        <Footer />
      </Container>
      <NotificationsUI />
      <ModalUI />
      <SidebarUI />
      <FeatureBar
        title="This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy."
        hide={acceptedCookies}
        action={
          <Button className="mx-5" onClick={() => onAcceptCookies()}>
            Accept cookies
          </Button>
        }
      />
    </>
  );
};

export default Layout;
