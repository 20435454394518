import Link from "next/link";
import React, { FC, forwardRef } from "react";

interface MyLinkProps extends React.PropsWithChildren<any> {
  href: string;
  external?: boolean;
}

export const MyLink: FC<MyLinkProps> = forwardRef<HTMLAnchorElement, MyLinkProps>((props, ref) => {
  const { href, children, external, ...rest } = props;

  const Tag = external ? "a" : Link;

  return (
    <Tag href={href} ref={ref} {...rest}>
      {children}
    </Tag>
  );
});

MyLink.displayName = "MyLink";
